// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contacts-js": () => import("./../../../src/templates/contacts.js" /* webpackChunkName: "component---src-templates-contacts-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-with-price-js": () => import("./../../../src/templates/pageWithPrice.js" /* webpackChunkName: "component---src-templates-page-with-price-js" */),
  "component---src-templates-price-black-js": () => import("./../../../src/templates/price/black.js" /* webpackChunkName: "component---src-templates-price-black-js" */),
  "component---src-templates-price-color-js": () => import("./../../../src/templates/price/color.js" /* webpackChunkName: "component---src-templates-price-color-js" */),
  "component---src-templates-price-js": () => import("./../../../src/templates/price.js" /* webpackChunkName: "component---src-templates-price-js" */)
}

